<template>
  <div class=container>
    <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">Visual Eyes<br>
            <img src="https://cave.cs.columbia.edu/old/software/visualeyes/imgs/veoverview_small.jpg" width="600" height="550"> </font></div></td>
          </tr>
          <tr>
            <td><div align="left"><font color="#000000"><b><i>VisualEyes</i></b> is a software which allows you to compute spherical panoramas, spherical environment maps and, most importantly, retinal images from images of eyes. As a result, it enables you to look around the world surrounding the person in the image, view it from the person's point of view, estimate the lighting conditions of the scene, etc.</font> <font color="#000000"> Please see <router-link to="/repository/VisualEyes/Tutorial">this tutorial</router-link> for a preview of what can be done with <i>VisualEyes</i>. </font> <font color="#000000"> For details of the underlying algorithm and implications, please refer to </font> </div>
              <blockquote>
                <div align="left">
				<br>
				<b>"The World in an Eye," </b><br> K. Nishino and S.K. Nayar, <br>IEEE Conference on Computer Vision and Pattern Recognition (CVPR), <br>Vol. I, pp. 444-451, Jun. 2004.<br> [<a href="http://www1.cs.columbia.edu/CAVE/publications/./pdfs/Nishino_CVPR04.pdf	" target="new">PDF</a>] [<a href="https://cave.cs.columbia.edu/reference?bid=177">bib</a>] [<a href="http://www1.cs.columbia.edu/CAVE/publications/copyright.html">©</a>] [<a href="https://cave.cs.columbia.edu/projects/categories/project?cid=Computational+Imaging&pid=The+World+In+An+Eye" target="new">Project Page</a>]<br>				<br>
				<b>"Eyes for Relighting," </b><br>K. Nishino and S.K. Nayar, <br>ACM Transactions on Graphics (also Proc. of SIGGRAPH), <br>Vol. 23, No. 3, pp. 704-711, Jul. 2004.<br> [<a href="http://www1.cs.columbia.edu/CAVE/publications/./pdfs/Nishino_TOG04.pdf	" target="new">PDF</a>] [<a href="https://cave.cs.columbia.edu/reference?bid=181">bib</a>] [<a href="http://www1.cs.columbia.edu/CAVE/publications/copyright.html">©</a>] [<a href="https://cave.cs.columbia.edu/projects/categories/project?cid=Computational+Imaging&pid=Eyes+for+Relighting" target="new">Project Page</a>]<br>				<br>
                </div>
              </blockquote>
            <font color="#000000"> In order to obtain a copy of the win32 binary code for <i>VisualEyes</i>, please carefully read the following copyright notice and fill out the request form. <b>To license the source code for <i>VisualEyes</i> for commercial applications, please contact visualeyes<img src="imgs/at.gif" width="10" height="10">cs.columbia.edu.</b> </font></td>
          </tr>
          <tr>
            <td><table border="0" cellpadding="5" cellspacing="0" bgcolor="#cccccc">
              <tbody><tr>
                <td align="left" bordercolor="#E2E2E2" bgcolor="#FFFFFF"><font color="#000000">
                  <center>
                    <br>
                    <b><i>VisualEyes</i></b><br>
                    <b>Copyright 2004 © Columbia University. All rights reserved.</b><br>
                  </center>
                  <br>
      Permission to use this software solely for educational and research purposes is hereby granted without fee. All other rights, including the rights to distribute, modify, or sell the software, are explicitly reserved by Columbia University. Please contact the authors if you with to make any modifications or additions to the software.<br>This software is provided "as is" and without warranty of any kind, express, implied or otherwise including without limitation, any warranty of merchantability or fitness for a particular purpose.<br>
      This software uses the FOX Toolkit Library (<a href="http://www.fox-toolkit.org">http://www.fox-toolkit.org</a>).
      </font><p><font color="#000000"> 
                </font></p></td>
              </tr>
            </tbody></table></td>
          </tr>
          <tr>
            <td> <div align="left">
              <table border="0" cellpadding="5" cellspacing="0" bgcolor="orange">
                <tbody>
                <tr>
                  <td align="left" bgcolor="#EEEEEE"><font color="#000000"> Please send data request to <b>nishino.ko.5a@kyoto-u.ac.jp</b> </font></td>
                </tr>

              </tbody></table>
              <font color="#000000"> </font> </div>              </td>
          </tr><tr>
                  <td align="left"><div align="center"><font color="#000000">					  Please send comments and questions to visualeyes<img src="imgs/at.gif" width="10" height="10">cs.columbia.edu </font></div></td>
                </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>